import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form, Button, Input, Row, Col, Select, message, Upload, Modal, Tooltip
} from 'antd';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import DownloadIcon from 'mdi-react/DownloadIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import './ImportFormSafra.scss';
import { download, importSafraRequest } from '../../dataSource/data_repository';
import { HTTPCodes } from '../../utils/constants/response-codes';

export default function ImportFormSafra() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  // const [isLeavingByCancelButton, setLeavingByCancelButton] = useAsyncState(false);
  const [safraFile, setSafraFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setuploadError] = useState(false);
  const [modalRepeatedData, setModalRepeatedData] = useState(false);

  function handleDownloadReport(id, name) {
    download({ url: `/report/downloadReport?id=${id}` }).then((responseFile) => {
      setLoading(false);

      console.log('responseFile ', responseFile);

      if (responseFile.status === HTTPCodes.SUCCESS) {
        const file = new Blob([responseFile.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const anchor = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        anchor.href = url;
        if (name.indexOf('.') !== -1) {
          anchor.download = `${name}.xlsx`;
        } else {
          anchor.download = name;
        }
        document.body.append(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        message.error('Ocorreu um erro ao exportar o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  const getDownloadIcon = (id, obj) => {
    return (
      <div className="action-container">
        {obj.fileStatus === 'generated' && (
          <div className="action-item" onClick={() => handleDownloadReport(id, obj.campaign)}>
            <DownloadIcon />
          </div>
        )}
        {obj.fileStatus === 'processing' && (
          <Tooltip
            className="action-item-disabled"
            title="Este relatório está sendo gerado e estará disponível em breve."
          >
            <DownloadIcon />
          </Tooltip>
        )}
      </div>
    );
  };


  const handleSubmit = async (ignoraRepetido) => {
    if (!safraFile.length) {
      setuploadError(safraFile.length === 0);
      return;
    }

    setUploading(true);

    const params = {
      campaign: form.getFieldValue('campaign'),
      deviceWindows: Number(form.getFieldValue('deviceWindows')) || 0,
      deviceMac: Number(form.getFieldValue('deviceMac')) || 0,
      deviceIphone: Number(form.getFieldValue('deviceIphone')) || 0,
      deviceMobileAndroid: Number(form.getFieldValue('deviceMobileAndroid')) || 0,
      deviceAndroid: Number(form.getFieldValue('deviceAndroid')) || 0,
      deviceIOS: Number(form.getFieldValue('deviceIOS')) || 0,
      date: moment(form.getFieldValue('date'), 'DD/MM/yyyy').format('yyyy-MM-DD'),
      ignoraRepetido,
    };

    console.log('params ', params);

    setUploading(true);

    importSafraRequest({
      safraFile: safraFile[0],
      params,
    }).then((response) => {
      setUploading(false);
      if (!response.error) {
        message.success('Importação realizada com sucesso');
        navigate('/relatorios');
      } else if (response.code === -7003) {
        setModalRepeatedData(response.report);
      } else {
        message.error('Ocorreu um erro ao realizar a importação');
      }
    }).catch((err) => {
      console.log('err ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setUploading(false);
    });
  };

  return (
    <div className="ImportFormSafra">

      <Form
        form={form}
        className="ImportFormSafraForm"
        onFinish={() => handleSubmit(false)}
        layout="vertical"
        initialValues={{
          email: '',
        }}
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Campanha"
              name="campaign"
              rules={[{ required: true, message: 'O campo campanha é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Data"
              name="date"
              validateTrigger="onBlur"
              rules={[
                { required: true },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    const dateFormat = 'DD/MM/YYYY';
                    const inputDate = moment(value, dateFormat, true);
                    if (!moment(inputDate).isValid()) {
                      return Promise.reject(new Error('Insira uma data válida'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                placeholder="dd/mm/aaaa"
                className="ant-input"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Windows"
              name="deviceWindows"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Mac"
              name="deviceMac"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Iphone"
              name="deviceIphone"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Dispositivo Móvel Android"
              name="deviceMobileAndroid"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Android"
              name="deviceAndroid"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="IOS"
              name="deviceIOS"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div style={{ height: 20 }}></div>
            <Upload
              onRemove={(file) => {
                const index = safraFile.indexOf(file);
                const newFileList = safraFile.slice();
                newFileList.splice(index, 1);
                setSafraFile(newFileList);
              }}
              beforeUpload={(file) => {
                setSafraFile([file]);
                setuploadError(false);
                return false;
              }}
              fileList={safraFile}
              accept=".csv"
            >
              <Button icon={<UploadOutlined />}>Selecionar arquivo lista</Button>
            </Upload>
            { uploadError && <div style={{ height: 20, color: '#ff4d4f' }}>O arquivo é obrigatório</div> }
          </Col>




        </Row>


        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => navigate('/relatorios')}
          >
            Cancelar
          </Button>

          <Button
            className="btn-primary btn-action"
            type="primary"
            htmlType="submit"
            // loading={isLoading}
            loading={uploading}
          >
            Importar
          </Button>
        </div>
      </Form>

      <Modal title="Atenção" visible={modalRepeatedData} onOk={() => handleSubmit(true)} onCancel={() => setModalRepeatedData(false)}>
        <div>Já existem relatórios semelhantes a este. Deseja continuar?</div>
        <br />
        <div>Relatórios:</div>
        {modalRepeatedData && modalRepeatedData?.map(
          v => {
            const content = `${v?.id} - ${v?.campaign} - ${moment(v?.date, 'yyyy-MM-DD').format('DD/MM/yyyy')}`;
            return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{v.quantity >= 1000 ? <div>{content}</div> : <a href={`/relatorio/${v?.id}`} target="_blank">{content}</a>}</div>
              <div style={{ cursor: 'pointer' }}>{getDownloadIcon(v.id, v)}</div>
            </div>);
          })
        }
      </Modal>
    </div>
  );
}
