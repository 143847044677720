import React, { useEffect, useState } from 'react';
import {
  Button, message, Spin, Pagination, Table, Tooltip, Popconfirm,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import TrashIcon from 'mdi-react/TrashIcon';
import EditIcon from 'mdi-react/EditIcon';
import './UserList.scss';
import { del, get } from '../../dataSource/data_repository';

export default function UserList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    currentTotal: 0,
    pageSize: 1,
    total: 0,
  });

  async function fetchData(page) {
    get({ url: `/user?page=${page - 1}` }).then((response) => {
      setLoading(false);
      if (!response.error) {
        const data = response.users;
        setListData(data);
        // setPaginationInfo({
        //   current: page,
        //   currentTotal: data.length,
        //   pageSize: 5,
        //   total: dataSource.length,
        // });
      } else {
        message.error('Erro ao carregar os dados');
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setLoading(false);
    });
  }

  function handleDelete(id) {
    setLoading(true);
    del({ url: `/user/${id}` }).then((response) => {
      setLoading(false);
      if (!response.error) {

        // if ((paginationInfo.current - 1) && paginationInfo.currentTotal === 1) {
        //   fetchData(paginationInfo.current - 1);
        // } else {
        fetchData(paginationInfo.current);
        // }

        message.success('Usuário excluído com sucesso');
      } else {
        message.error('Ocorreu um erro ao excluir o usuário');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Empresa',
      dataIndex: 'companies',
      key: 'companies',
      render: (companies) => companies.reduce((a, v, i) => `${a}${i ? ', ' : ''}${v.name}`, ''),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        if (type === 'ADMIN') {
          return 'Administrador';
        }
        if (type === 'USER') {
          return 'Usuário';
        }
        return type
      }
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, obj) => {
        return (
          <div className="action-container">
            <a className="action-item" href={`/usuarios/editar/${id}`}>
              <EditIcon />
            </a>
            <Popconfirm
              title="Tem certeza que deseja excluir este usuário?"
              onConfirm={() => handleDelete(id)}
              okText="Sim"
              cancelText="Não"
            >
              <div className="action-item">
                <TrashIcon />
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <div className="UserList">
      <Spin
        tip="Carregando"
        spinning={loading}
      >
        <Button
          className="btn-primary btn-add-new"
          onClick={() => navigate('/usuarios/cadastrar')}
        >
          Cadastrar novo
        </Button>
        <Table dataSource={listData} columns={columns} pagination={false} rowKey="id" />
        {/* <Pagination */}
        {/*  className="pagination-container" */}
        {/*  onChange={(page) => { fetchData(page); }} */}
        {/*  defaultCurrent={1} */}
        {/*  current={paginationInfo.current} */}
        {/*  pageSize={paginationInfo.pageSize} */}
        {/*  total={paginationInfo.total} */}
        {/* /> */}
      </Spin>
    </div>
  );
}
