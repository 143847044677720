const API_URL = process.env.REACT_APP_SERVER_NAME;
const INVALID_ACCESS_TOKEN_ERROR_CODE = '401';
const INVALID_REFRESH_TOKEN_ERROR_CODE = '';
const AUTH_URL = '/authentications';
const SAFRA_ID = 7;

// for browser storage encryption
const ENCRYPTION_KEY = 'uNDxaABR9TFJV4LzJEeKxRzhSVXt3qU7';
const VERSION = '3.0.0';

const STATES = [
  { initials: 'AC', state: 'Acre' },
  { initials: 'AL', state: 'Alagoas' },
  { initials: 'AP', state: 'Amapá' },
  { initials: 'AM', state: 'Amazonas' },
  { initials: 'BA', state: 'Bahia' },
  { initials: 'CE', state: 'Ceará' },
  { initials: 'DF', state: 'Distrito Federal' },
  { initials: 'ES', state: 'Espírito Santo' },
  { initials: 'GO', state: 'Goiás' },
  { initials: 'MA', state: 'Maranhão' },
  { initials: 'MT', state: 'Mato Grosso' },
  { initials: 'MS', state: 'Mato Grosso do Sul' },
  { initials: 'MG', state: 'Minas Gerais' },
  { initials: 'PA', state: 'Pará' },
  { initials: 'PB', state: 'Paraíba' },
  { initials: 'PR', state: 'Paraná' },
  { initials: 'PE', state: 'Pernambuco' },
  { initials: 'PI', state: 'Piauí' },
  { initials: 'RJ', state: 'Rio de Janeiro' },
  { initials: 'RN', state: 'Rio Grande do Norte' },
  { initials: 'RS', state: 'Rio Grande do Sul' },
  { initials: 'RO', state: 'Rondônia' },
  { initials: 'RR', state: 'Roraima' },
  { initials: 'SC', state: 'Santa Catarina' },
  { initials: 'SP', state: 'São Paulo' },
  { initials: 'SE', state: 'Sergipe' },
  { initials: 'TO', state: 'Tocantins' },
];

const WEEKDAYS = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
  'Feriados',
];

const MONTH_OF_THE_YEAR = {
  JANUARY: 'Janeiro',
  FEBRUARY: 'Fevereiro',
  MARCH: 'Março',
  APRIL: 'Abril',
  MAY: 'Maio',
  JUNE: 'Junho',
  JULY: 'Julho',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Setembro',
  OCTOBER: 'Outubro',
  NOVEMBER: 'Novembro',
  DECEMBER: 'Dezembro',
};

export {
  API_URL,
  AUTH_URL,
  ENCRYPTION_KEY,
  INVALID_ACCESS_TOKEN_ERROR_CODE,
  INVALID_REFRESH_TOKEN_ERROR_CODE,
  MONTH_OF_THE_YEAR,
  STATES,
  VERSION,
  WEEKDAYS,
  SAFRA_ID,
};
