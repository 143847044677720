import React, { useEffect, useState } from 'react';
import {
  message,
  Spin,
  Table,
  Row,
  Col,
  Form,
  Select,
  Button,
} from 'antd';
import moment from 'moment';
import './MonthTotalList.scss';
import { get } from '../../../dataSource/data_repository';

const initialData = {
  month: Number(moment().subtract(1, 'months').format('MM')).toString(),
  year: Number(moment().subtract(1, 'months').format('yyyy')).toString(),
  type: 'WhatsApp',
};

export default function MonthTotalList() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [totalMes, setTotalMes] = useState(0);

  function getFilterString() {
    let filterString = '';
    const monthField = form.getFieldValue('month');
    if (monthField) {
      filterString = `${filterString}&month=${monthField}`;
    }
    const yearField = form.getFieldValue('year');
    if (yearField) {
      filterString = `${filterString}&year=${yearField}`;
    }
    const typeField = form.getFieldValue('type');
    if (typeField) {
      filterString = `${filterString}&type=${typeField}`;
    }
    return filterString;
  }

  async function fetchData(page) {
    const filterString = getFilterString();
    console.log('filterString ', filterString);
    get({ url: `/reportMonth/total?page=${page - 1}${filterString}` }).then((response) => {
      setLoading(false);
      if (!response.error) {
        // const data = dataSource.slice((page - 1) * 5, page * 5);
        const { reports } = response;
        setListData(reports);
        const total = reports.reduce((a, v) => (a + v.total), 0);
        setTotalMes(total);
      } else {
        message.error('Erro ao carregar os dados');
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  const columns = [
    {
      title: 'Empresa',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Grupo',
      dataIndex: 'companyGroupName',
      key: 'companyGroupName',
    },
    {
      title: 'Total enviado',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return (
    <div className="MonthTotalList">
      <Spin
        tip="Carregando"
        spinning={loading}
      >

        <Form
          form={form}
          onFinish={() => fetchData(1)}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24} className="filters-container">
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Mês"
                name="month"
                rules={[{ required: true, message: 'Por favor, selecione um mês' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="01" value="1">Janeiro</Select.Option>
                  <Select.Option key="02" value="2">Fevereiro</Select.Option>
                  <Select.Option key="03" value="3">Março</Select.Option>
                  <Select.Option key="04" value="4">Abril</Select.Option>
                  <Select.Option key="05" value="5">Maio</Select.Option>
                  <Select.Option key="06" value="6">Junho</Select.Option>
                  <Select.Option key="07" value="7">Julho</Select.Option>
                  <Select.Option key="08" value="8">Agosto</Select.Option>
                  <Select.Option key="09" value="9">Setembro</Select.Option>
                  <Select.Option key="10" value="10">Outubro</Select.Option>
                  <Select.Option key="11" value="11">Novembro</Select.Option>
                  <Select.Option key="12" value="12">Dezembro</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Ano"
                name="year"
                rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="2021" value="2021">2021</Select.Option>
                  <Select.Option key="2022" value="2022">2022</Select.Option>
                  <Select.Option key="2023" value="2023">2023</Select.Option>
                  <Select.Option key="2024" value="2024">2024</Select.Option>
                  <Select.Option key="2025" value="2025">2025</Select.Option>
                  <Select.Option key="2026" value="2026">2026</Select.Option>
                  <Select.Option key="2027" value="2027">2027</Select.Option>
                  <Select.Option key="2028" value="2028">2028</Select.Option>
                  <Select.Option key="2029" value="2029">2029</Select.Option>
                  <Select.Option key="2030" value="2030">2030</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="WhatsApp" value="WhatsApp">WhatsApp</Select.Option>
                  <Select.Option key="SMS" value="SMS">SMS</Select.Option>
                  <Select.Option key="E-mail" value="E-mail">E-mail</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              {/*<Button*/}
              {/*  disable={loading}*/}
              {/*  className="SecondaryButton button-filter"*/}
              {/*  type="primary"*/}
              {/*  onClick={cleanData}*/}
              {/*>*/}
              {/*  Limpar*/}
              {/*</Button>*/}
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Button
                disable={loading}
                className="PrimaryButton button-filter"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>

        {!!totalMes && (
          <div style={{ marginBottom: 50 }}><b>Total enviado no mês:</b> {totalMes}</div>
        )}

        <Table dataSource={listData} columns={columns} pagination={false} rowKey="id" />
        {/*<Pagination*/}
        {/*  className="pagination-container"*/}
        {/*  onChange={(page) => { fetchData(page); }}*/}
        {/*  defaultCurrent={1}*/}
        {/*  current={paginationInfo.current}*/}
        {/*  pageSize={paginationInfo.pageSize}*/}
        {/*  total={paginationInfo.total}*/}
        {/*/>*/}
      </Spin>
    </div>
  );
}
