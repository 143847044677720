// React Imports
import React from 'react';
import PropTypes from 'prop-types';

// Ant Design, Icons and Stylesheet
import { Result, Button } from 'antd';
import './NotFound.scss';

import { routesPaths } from '../../router/RoutesPaths';

const NotFound = (props) => (
  <div className="notFound">
    <Result status="404" title="Erro 404" subTitle="Página não encontrada.">
      <Button
        type="primary"
        className="btn-go-back"
        onClick={() => props.navigate(routesPaths.root)}
      >
        VOLTAR À PÁGINA INICIAL
      </Button>
    </Result>
  </div>
);

NotFound.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

NotFound.defaultProps = {};

export default NotFound;
