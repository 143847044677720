import React, { useEffect, useState } from 'react';
import {
  message,
  Spin,
  Pagination,
  Table,
  Row,
  Col,
  Form,
  Select,
  Button,
  Popconfirm,
  Tooltip,
} from 'antd';
import EditIcon from 'mdi-react/EditIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import './ReportList.scss';
import { download, get, del, post } from '../../dataSource/data_repository';
import { selectStringFilter } from '../../utils/forms';
import { HTTPCodes } from '../../utils/constants/response-codes';
import { getUserPermissions } from '../../utils/users';

const initialData = {
  company: [],
  type: 'Todos',
  numberField: null,
  selectField: 1,
};

export default function ReportList() {
  const [form] = Form.useForm();
  const [listCompanies, setListCompanies] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState([]);
  const [listData, setListData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    currentTotal: 0,
    pageSize: 1,
    total: 0,
  });

  useEffect(() => {
    setLoading(true);
    get({ url: '/company' }).then((response) => {
      setLoading(false);
      if (!response.error) {
        setListCompanies(response.companies);
      } else {
        message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      setLoading(false);
    });
    setIsAdmin(getUserPermissions().indexOf('EDIT_SYSTEM_PARAMETER') !== -1);
  }, []);

  function getFilterString() {
    let filterString = '';
    const company = form.getFieldValue('company');
    if (company && company !== 'Todos') {
      filterString = `${filterString}&companyId=${company}`;
    }
    const type = form.getFieldValue('type');
    if (type && type !== 'Todos') {
      filterString = `${filterString}&type=${type}`;
    }
    const date = form.getFieldValue('date');
    if (date) {
      filterString = `${filterString}&date=${moment(`01/${date}`, 'DD-MM-yyyy').format('yyyy-MM-DD')}`;
    }
    return filterString;
  }

  async function fetchData(page) {
    const filterString = getFilterString();
    console.log('filterString ', filterString);
    get({ url: `/report?page=${page - 1}${filterString}` }).then((response) => {
      setLoading(false);
      if (!response.error) {
        // const data = dataSource.slice((page - 1) * 5, page * 5);
        const { reports } = response;
        setListData(reports.content);
        setPaginationInfo({
          current: page,
          currentTotal: reports.numberOfElements,
          pageSize: reports.size,
          total: reports.totalElements,
        });
      } else {
        message.error('Erro ao carregar os dados');
      }
      setLoading(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro na requisição. Por favor, tente novamente mais tarde');
      setLoading(false);
    });
  }

  async function cleanData() {
    // reset fields
    form.resetFields();
    fetchData(1);
  }

  function handleDownloadReport(id, name) {
    setLoading(true);
    download({ url: `/report/downloadReport?id=${id}` }).then((responseFile) => {
//     download({ url: `/phpApi/downloadReport?id=${id}` }).then((responseFile) => {
      setLoading(false);

      console.log('responseFile ', responseFile);

      if (responseFile.status === HTTPCodes.SUCCESS) {
        const file = new Blob([responseFile.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const anchor = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        anchor.href = url;
        if (name.indexOf('.') !== -1) {
          anchor.download = `${id}-${name}.xlsx`;
        } else {
          anchor.download = `${id}-${name}`;
        }
        document.body.append(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        message.error('Ocorreu um erro ao exportar o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  function handleDelete(id) {
    setLoading(true);
    del({ url: `/report?id=${id}` }).then((response) => {
      setLoading(false);
      if (!response.error) {

        // Remove e refaz a busca
        if ((paginationInfo.current - 1) && paginationInfo.currentTotal === 1) {
          // Se não é a página 1 e só tem um elemento na lista
          // Faz a busca com o índice da página anterior
          fetchData(paginationInfo.current - 1);
        } else {
          fetchData(paginationInfo.current);
        }

        message.success('Relatório excluído com sucesso');
      } else {
        message.error('Ocorreu um erro ao excluir o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  function handleRefresh(id) {
    setRefreshing([...refreshing, id]);
    post({ url: `/import/generateFile?id=${id}` }).then((response) => {
      setRefreshing(refreshing.filter((v) => v !== id));
      if (!response.error) {
        setListData(listData.map((v) => {
          if (v.id === id) {
            return { ...v, fileStatus: 'generated' };
          }
          return v;
        }));
        // message.success('Relatório excluído com sucesso');
      } else {
        // message.error('Ocorreu um erro ao excluir o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setRefreshing(refreshing.filter((v) => v !== id));
    });
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Empresa',
      dataIndex: 'company',
      key: 'company',
      render: (company) => (company.name),
    },
    {
      title: 'Campanha',
      dataIndex: 'campaign',
      key: 'campaign',
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (date) => moment(date, 'yyyy-MM-DD').format('DD/MM/yyyy'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        if (type === 'whatsapp') {
          return 'WhatsApp';
        }
        if (type === 'email') {
          return 'E-mail';
        }
        if (type === 'sms') {
          return 'SMS';
        }
        return type;
      },
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    // {
    //   title: 'Assunto',
    //   dataIndex: 'campaign',
    //   key: 'campaign',
    // },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id, obj) => {
        return (
          <div className="action-container">
            {obj.fileStatus === 'generated' && (
              <div className="action-item" onClick={() => handleDownloadReport(id, obj.campaign)}>
                <DownloadIcon />
              </div>
            )}
            {obj.fileStatus === 'processing' && (
              <Tooltip
                className="action-item-disabled"
                title="Este relatório está sendo gerado e estará disponível em breve."
              >
                <DownloadIcon />
              </Tooltip>
            )}
            {obj.fileStatus !== 'generated' && (
              refreshing.indexOf(id) === -1 ? (isAdmin && (
                <div className="action-item" onClick={() => handleRefresh(id)}>
                  <RefreshIcon />
                </div>
              )) : (
                <Tooltip
                  className="action-item rotating action-item-disabled"
                  title="Este relatório está sendo gerado e estará disponível em breve."
                >
                  <RefreshIcon />
                </Tooltip>
              )
            )}
            {obj.quantity < 1000 ? (
              <a className="action-item" href={`/relatorio/${id}`}>
                <EyeIcon />
              </a>
            ) : (
              <Tooltip
                className="action-item-disabled"
                title="Este relatório é grande demais para ser visualizado no navegador."
              >
                <EyeIcon />
              </Tooltip>
            )}
            { isAdmin && (
              <Popconfirm
                title="Tem certeza que deseja excluir este relatório?"
                onConfirm={() => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <div className="action-item">
                  <TrashIcon />
                </div>
              </Popconfirm>
            )}
            {isAdmin && obj.fileStatus === 'generated' && obj.type === 'WhatsApp' && (
              <a className="action-item" href={`/editar/${id}`}>
                <EditIcon />
              </a>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <div className="ReportList">
      <Spin
        tip="Carregando"
        spinning={loading}
      >

        <Form
          form={form}
          onFinish={() => fetchData(1)}
          initialValues={initialData}
          labelAlign="left"
          layout="vertical"
          noValidate // disable HTML5 validation
        >
          <Row gutter={24} className="filters-container">
            {listCompanies.length > 1 && (
              <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <Form.Item
                  label="Empresa"
                  name="company"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    filterOption={selectStringFilter}
                    style={{ minWidth: 80, width: '100%' }}
                  >
                    {
                      listCompanies.map(v => (
                        <Select.Option key={v.id} value={v.id}>
                          {v.companyGroup?.name ? `${v.name} - ${v.companyGroup?.name}` : v.name}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Tipo"
                name="type"
                rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
              >
                <Select style={{ minWidth: 80, width: '100%' }}>
                  <Select.Option key="Todos" value="Todos">Todos</Select.Option>
                  <Select.Option key="WhatsApp" value="WhatsApp">WhatsApp</Select.Option>
                  <Select.Option key="SMS" value="SMS">SMS</Select.Option>
                  <Select.Option key="E-mail" value="E-mail">E-mail</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label="Data"
                name="date"
                validateTrigger="onBlur"
                rules={[
                  { required: false },
                  () => ({
                    validator(rule, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      const dateFormat = 'DD/MM/YYYY';
                      const inputDate = moment(`01/${value}`, dateFormat, true);
                      if (!moment(inputDate).isValid()) {
                        return Promise.reject(new Error('Insira uma data válida'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="mm/aaaa"
                  className="ant-input"
                />
              </Form.Item>
            </Col>
            {!(listCompanies.length > 1) && <Col xs={{ span: 24 }} lg={{ span: 6 }} />}
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              <Button
                disable={loading}
                className="SecondaryButton button-filter"
                type="primary"
                onClick={cleanData}
              >
                Limpar
              </Button>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 3 }}>
              <Button
                disable={loading}
                className="PrimaryButton button-filter"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Filtrar
              </Button>
            </Col>
          </Row>
        </Form>


        <Table dataSource={listData} columns={columns} pagination={false} rowKey="id" />
        <Pagination
          className="pagination-container"
          onChange={(page) => { fetchData(page); }}
          defaultCurrent={1}
          current={paginationInfo.current}
          pageSize={paginationInfo.pageSize}
          total={paginationInfo.total}
          showSizeChanger={false}
        />
      </Spin>
    </div>
  );
}
