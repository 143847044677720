import { get, put } from './data_repository';

function getMyProfile() {
  const endPoint = '/user/myProfile/get/';

  return new Promise((resolve, reject) => {
    get({ url: endPoint })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

function editMyProfile({ name, email, telephone }) {
  const endPoint = '/user/myProfile/edit/';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: { name, email, telephone } })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

function changePassword({ oldPassword, newPassword }) {
  const endPoint = '/user/updatePassword/';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: { oldPassword, newPassword } })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export {
  getMyProfile,
  editMyProfile,
  changePassword,
};
