// React Imports
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// External Packages

// Ant Design, Icons and Stylesheet
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import './EmailConfirmation.scss';

// Assets, Constants and Resources
import logo from '../../assets/images/logo-tyra.png';
import { EmailConfirmationCodes } from '../../utils/constants/response-codes';
import { EmailConfirmationMessages } from '../../assets/strings/strings';

// Services, Requests and Utilities
import { routesPaths } from '../../router/RoutesPaths';
import { emailConfirmation } from '../../dataSource/auth_requests';

// Components

const EmailConfirmationComponent = () => {
  const initialMessage = (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );

  const [message, setMessage] = useState(initialMessage);

  const [button, setButton] = useState();

  const { token } = useParams();

  const doLoginButton = (
    <Link to={routesPaths.root}>
      <Button type="primary">
        {EmailConfirmationMessages.doLogin.toUpperCase()}
      </Button>
    </Link>
  );

  useEffect(() => {
    async function sendEmailConfirmation() {
      const response = await emailConfirmation({ token });

      setButton(doLoginButton);

      switch (response.code) {
        case EmailConfirmationCodes.SUCCESS:
          setMessage(
            <div>
              <p>
                <strong>{EmailConfirmationMessages.emailConfirmedSuccessfully}</strong>
              </p>
              <p>{EmailConfirmationMessages.loginNow}</p>
            </div>,
          );

          break;

        case EmailConfirmationCodes.error.EMAIL_TOKEN_NOT_FOUND:
        case EmailConfirmationCodes.error.INVALID_EMAIL_TOKEN:
          setMessage(
            <div>
              <p>
                <strong>{EmailConfirmationMessages.invalidToken}</strong>
              </p>
            </div>,
          );
          break;

        default:
          break;
      }
    }

    sendEmailConfirmation();
  }, []);

  return (
    <div className="EmailConfirmation">
      <div className="box">
        <img
          className="logo-image"
          src={logo}
          alt=""
        />
        <div className="message">
          {message}
        </div>
        {button}
      </div>
    </div>
  );
};

EmailConfirmationComponent.propTypes = {};

EmailConfirmationComponent.defaultProps = {};

export default EmailConfirmationComponent;
