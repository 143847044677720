import React, { useEffect, useState } from 'react';
import { Col, message, Row, Spin, Table } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './ReportDetails.scss';
import { get } from '../../../dataSource/data_repository';
import { SAFRA_ID } from '../../../utils/constants/constants';

const columnsDefault = [
  {
    title: 'Empresa',
    dataIndex: 'empresa',
    key: 'empresa',
  },
  {
    title: 'Tipo_Campanha',
    dataIndex: 'tipoCampanha',
    key: 'tipoCampanha',
  },
  {
    title: 'Campanha',
    dataIndex: 'campanha',
    key: 'campanha',
  },
  {
    title: 'Base',
    dataIndex: 'base',
    key: 'base',
  },
  {
    title: 'Tipo_Comunic',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Possui_Imagem',
    dataIndex: 'possuiImagem',
    key: 'possuiImagem',
  },
  {
    title: 'Remetente',
    dataIndex: 'remetente',
    key: 'remetente',
  },
  {
    title: 'Assunto',
    dataIndex: 'assunto',
    key: 'assunto',
  },
  {
    title: 'Mensagem',
    dataIndex: 'mensagem',
    key: 'mensagem',
  },
  {
    title: 'Destinatario',
    dataIndex: 'destinatario',
    key: 'destinatario',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Enviado',
    dataIndex: 'enviado',
    key: 'enviado',
  },
  {
    title: 'Data_Envio',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Hora_Envio',
    dataIndex: 'horaEnvio',
    key: 'horaEnvio',
  },
  {
    title: 'Recebido',
    dataIndex: 'recebido',
    key: 'recebido',
  },
  {
    title: 'Data_Recebido',
    dataIndex: 'dataRecebido',
    key: 'dataRecebido',
  },
  {
    title: 'Hora_Recebido',
    dataIndex: 'horaRecebido',
    key: 'horaRecebido',
  },
  {
    title: 'Aberto',
    dataIndex: 'aberto',
    key: 'aberto',
  },
  {
    title: 'Data_Abertura',
    dataIndex: 'dataAbertura',
    key: 'dataAbertura',
  },
  {
    title: 'Hora_Abertura',
    dataIndex: 'horaAbertura',
    key: 'horaAbertura',
  },
  {
    title: 'Contador_Aberto',
    dataIndex: 'contadorAberto',
    key: 'contadorAberto',
  },
  {
    title: 'Clique',
    dataIndex: 'clique',
    key: 'clique',
  },
  {
    title: 'Contador_Clique',
    dataIndex: 'contadorClique',
    key: 'contadorClique',
  },
  {
    title: 'Soft_Bounced',
    dataIndex: 'softBounced',
    key: 'softBounced',
  },
  {
    title: 'Hard_Bounced',
    dataIndex: 'hardBounced',
    key: 'hardBounced',
  },
  {
    title: 'Canc_Subs',
    dataIndex: 'cancSubs',
    key: 'cancSubs',
  },
  {
    title: 'Data_Canc',
    dataIndex: 'dataCanc',
    key: 'dataCanc',
  },
];

const columnsSafra = [
  {
    title: 'Destinatário',
    dataIndex: 'destinatario',
    key: 'destinatario',
  },
  {
    title: 'Data do disparo',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Título',
    dataIndex: 'campaign',
    key: 'campaign',
  },
  {
    title: 'Aberturas únicas',
    dataIndex: 'contadorAberto',
    key: 'contadorAberto',
  },
  {
    title: 'Aberturas em 24hrs',
    dataIndex: 'dataAbertura',
    key: 'dataAbertura',
  },
  {
    title: 'Aberturas em 48hrs',
    dataIndex: 'horaAbertura',
    key: 'horaAbertura',
  },
  {
    title: 'Cliques únicos',
    dataIndex: 'contadorClique',
    key: 'contadorClique',
  },
  {
    title: 'Hard Bounce',
    dataIndex: 'hardBounced',
    key: 'hardBounced',
  },
  {
    title: 'Soft Bounce',
    dataIndex: 'softBounced',
    key: 'softBounced',
  },
  {
    title: 'Removidos',
    dataIndex: 'dataCanc',
    key: 'dataCanc',
  },
  {
    title: 'Dispositivo acessado',
    dataIndex: 'base',
    key: 'base',
  },
];

export default function ReportDetails() {
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [type, setType] = useState('');
  const [columns, setColumns] = useState(columnsDefault);
  const [isSafra, setIsSafra] = useState(false);

  const { id } = useParams();

  async function fetchData() {
    get({ url: `/report/detail?id=${id}` }).then((response) => {
      setLoadingData(false);
      console.log('response ', response);

      if (!response.error) {
        if (response.report.company.id === SAFRA_ID && response.report?.type === 'E-mail') {
          setColumns(columnsSafra);
          setIsSafra(true);
        } else {
          setColumns(columnsDefault);
          setIsSafra(false);
        }
        setListData(response.listReportDetail.map((v) => ({
          ...v,
          campaign: response.report.campaign,
          date: moment(response.report?.date, 'yyyy-MM-DD').format('DD/MM/yyyy'),
        })));
        setDate(moment(response.report?.date, 'yyyy-MM-DD').format('DD/MM/yyyy'));
        setQuantity(response.report?.quantity);
        setType(response.report?.type);
      } else {
        message.error('Ocorreu um erro ao buscar os dados');
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro ao buscar os dados');
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (loadingData) {
      fetchData();
    }
    document.title = 'Visualizar relatório';
  }, []);

  return (
    <div className="ReportDetails">
      <Spin
        tip="Carregando"
        spinning={loadingData}
      >
        <div className="title-container">
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <b>Data: </b>{date}
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <b>Quantidade: </b>{quantity}
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <b>Tipo: </b>{type}
            </Col>
          </Row>
        </div>
        <div className="report-table-container">
          <Table
            className={isSafra ? 'report-table-safra' : 'report-table'}
            dataSource={listData}
            columns={columns}
            pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
}
