import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Select,
  message,
  Upload,
  Modal,
  Tooltip,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DownloadIcon from 'mdi-react/DownloadIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import moment from 'moment';
import './ImportFormSMS.scss';
import { download, get, importSMSRequest } from '../../dataSource/data_repository';
import { HTTPCodes } from '../../utils/constants/response-codes';
import { selectStringFilter } from '../../utils/forms';

export default function ImportFormSMS() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [listCompanies, setListCompanies] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [isLeavingByCancelButton, setLeavingByCancelButton] = useAsyncState(false);
  const [comteleFile, setComteleFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [upload2Error, setUpload2Error] = useState(false);
  const [modalRepeatedData, setModalRepeatedData] = useState(false);

  function handleDownloadReport(id, name) {
    download({ url: `/report/downloadReport?id=${id}` }).then((responseFile) => {
      setLoading(false);

      console.log('responseFile ', responseFile);

      if (responseFile.status === HTTPCodes.SUCCESS) {
        const file = new Blob([responseFile.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const anchor = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        anchor.href = url;
        if (name.indexOf('.') !== -1) {
          anchor.download = `${name}.xlsx`;
        } else {
          anchor.download = name;
        }
        document.body.append(anchor);
        anchor.click();
        setTimeout(() => {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        message.error('Ocorreu um erro ao exportar o relatório');
      }
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });
  }

  const getDownloadIcon = (id, obj) => {
    return (
      <div className="action-container">
        {obj.fileStatus === 'generated' && (
          <div className="action-item" onClick={() => handleDownloadReport(id, obj.campaign)}>
            <DownloadIcon />
          </div>
        )}
        {obj.fileStatus === 'processing' && (
          <Tooltip
            className="action-item-disabled"
            title="Este relatório está sendo gerado e estará disponível em breve."
          >
            <DownloadIcon />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleSubmit = async (ignoraRepetido) => {

    if (!comteleFile.length) {
      setUpload2Error(comteleFile.length === 0);
      return;
    }

    setUploading(true);

    const params = {
      campaign: form.getFieldValue('campaign'),
      base: form.getFieldValue('base'),
      companyId: form.getFieldValue('company'),
      message: form.getFieldValue('mensagem'),
      ignoraRepetido,
    };

    console.log('params ', params);

    setUploading(true);
    importSMSRequest({
      comteleFile: comteleFile[0],
      params,
    }).then((response) => {
      setUploading(false);
      if (!response.error) {
        message.success('Importação realizada com sucesso');
        navigate('/relatorios');
      } else if (response.code === -7003) {
        setModalRepeatedData(response.report);
      } else {
        message.error('Ocorreu um erro ao realizar a importação');
      }
    }).catch((err) => {
      console.log('err ', err);
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setUploading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    get({ url: '/company' }).then((response) => {
      setLoading(false);
      if (!response.error) {
        setListCompanies(response.companies);
      } else {
        message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro ao buscar os dados da lista de empresas');
      setLoading(false);
    });
  }, []);

  return (
    <div className="ImportFormSMS">

      <Form
        form={form}
        className="ImportFormSMSForm"
        onFinish={() => handleSubmit(false)}
        layout="vertical"
        initialValues={{
          email: '',
          company: 1,
        }}
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Campanha"
              name="campaign"
              rules={[{ required: true, message: 'O campo campanha é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Base"
              name="base"
              rules={[{ required: true, message: 'O campo base é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Empresa"
              name="company"
              rules={[{ required: true, message: 'Por favor, selecione uma opção' }]}
            >
              <Select style={{ minWidth: 80, width: '100%' }} filterOption={selectStringFilter}>
                {
                  listCompanies.map(v => (
                    <Select.Option key={v.id} value={v.id}>
                      {v.companyGroup?.name ? `${v.name} - ${v.companyGroup?.name}` : v.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Mensagem"
              name="mensagem"
              rules={[{ required: true, message: 'O campo Mensagem é obrigatório' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          {/*/!*sempre como enviada*!/*/}
          {/*<Col xs={{ span: 24 }} lg={{ span: 12 }}>*/}
          {/*  <Form.Item*/}
          {/*    label="Status"*/}
          {/*    name="status"*/}
          {/*    rules={[{ required: true, message: 'O campo status é obrigatório' }]}*/}
          {/*  >*/}
          {/*    <Select style={{ minWidth: 80, width: '100%' }}>*/}
          {/*      <Select.Option key="Enviada" value="Enviada">Enviada</Select.Option>*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}

          {/*/!*status SMS vai ser a coluna f do Comtele*!/*/}
          {/*/!*enviado sim erro não*!/*/}
          {/*<Col xs={{ span: 24 }} lg={{ span: 12 }}>*/}
          {/*  <Form.Item*/}
          {/*    label="Enviado?"*/}
          {/*    name="enviado"*/}
          {/*    rules={[{ required: true, message: 'O campo é obrigatório' }]}*/}
          {/*  >*/}
          {/*    <Select style={{ minWidth: 80, width: '100%' }}>*/}
          {/*      <Select.Option key="Sim" value="Sim">Sim</Select.Option>*/}
          {/*      <Select.Option key="Não" value="Não">Não</Select.Option>*/}
          {/*    </Select>*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div style={{ height: 20 }}></div>
            <Upload
              onRemove={(file) => {
                const index = comteleFile.indexOf(file);
                const newFileList = comteleFile.slice();
                newFileList.splice(index, 1);
                setComteleFile(newFileList);
              }}
              beforeUpload={(file) => {
                setComteleFile([file]);
                setUpload2Error(false);
                return false;
              }}
              fileList={comteleFile}
              accept=".csv"
            >
              <Button icon={<UploadOutlined />}>Selecionar arquivo CSV</Button>
            </Upload>
            { upload2Error && <div style={{ height: 20, color: '#ff4d4f' }}>O arquivo é obrigatório</div> }
          </Col>




        </Row>


        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => navigate('/relatorios')}
          >
            Cancelar
          </Button>

          <Button
            className="btn-primary btn-action"
            type="primary"
            htmlType="submit"
            // loading={isLoading}
            loading={uploading}
          >
            Importar
          </Button>
        </div>
      </Form>

      <Modal title="Atenção" visible={modalRepeatedData} onOk={() => handleSubmit(true)} onCancel={() => setModalRepeatedData(false)}>
        <div>Já existem relatórios semelhantes a este. Deseja continuar?</div>
        <br />
        <div>Relatórios:</div>
        {modalRepeatedData && modalRepeatedData?.map(
          v => {
            const content = `${v?.id} - ${v?.campaign} - ${moment(v?.date, 'yyyy-MM-DD').format('DD/MM/yyyy')}`;
            return (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{v.quantity >= 1000 ? <div>{content}</div> : <a href={`/relatorio/${v?.id}`} target="_blank">{content}</a>}</div>
              <div style={{ cursor: 'pointer' }}>{getDownloadIcon(v.id, v)}</div>
            </div>);
          })
        }
      </Modal>
    </div>
  );
}
