import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Layout from '../layout/Layout';
import { checkUserIsAuth, removeUserFromStorage, getUserPermissions } from '../utils/users';
import { routesPaths } from './RoutesPaths';
import MessageUtils from '../utils/message-utils';
import { GeneralMessages } from '../assets/strings/strings';

const PrivateRoute = (props) => {

  const userPermissions = getUserPermissions() || [];

  if (!checkUserIsAuth()) {
    // Clean storage token
    removeUserFromStorage();
    return <Navigate to={{ pathname: routesPaths.login }} />;
  }

  // if user has no permission
  if (!(props.permissions || [])
    .some((element) => userPermissions.includes(element))) {
    MessageUtils.warning({
      message: GeneralMessages.permissionDenied,
      description: GeneralMessages.redirectToHome,
    });

//    if (props.location.pathname === routesPaths.root) {
//      removeUserFromStorage();
//      return <Navigate to={{ pathname: routesPaths.login }} />;
//    }
    return <Navigate to={{ pathname: routesPaths.root }} />;
  }

  if (checkUserIsAuth()) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
  return <Navigate to={{ pathname: routesPaths.login }} />;


//  const { user } = useAuth();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
//  return (user != null) ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
