import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getUserPermissions } from '../../utils/users';
import './SidebarComponent.scss';
import logo from '../../assets/images/logo-tyra.png';
import logoCollapsed from '../../assets/images/logo-tyra.png';
import { VERSION } from '../../utils/constants/constants';
import { toggleMenuCollapsed, changeHeaderTitle } from '../../redux/actions/app';
import { routesPaths } from '../../router/RoutesPaths';

import { menuItens } from './MenuItens';

const { Sider } = Layout;

const SidebarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName, setPathName] = useState('');

  const collapsed = useSelector((state) => state.app.collapsed);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(toggleMenuCollapsed());
  };

  const changeTitle = (headerTitle) => {
    dispatch(changeHeaderTitle(headerTitle));
  };

  const getPageTitle = () => {
    // Return the first page title that contains the current browser URL path name
    for (let i = 0; i < menuItens.length; i += 1) {
      if (menuItens[i].standalone && location.pathname.startsWith(menuItens[i].url)) {
        return menuItens[i];
      }

      const found = menuItens[i].subMenu.find(
        (item) => location.pathname.startsWith(item.url),
      );

      if (found !== undefined) {
        return found;
      }
    }

    return { documentTitle: '', headerTitle: '' };
  };

  const getOpenKeys = () => {
    return (
      // Return open menu keys for a URL path name
      menuItens.map((v, i) => {
        const foundUrl = v.subMenu.reduce(
          (prev, elem) => prev || (location.pathname.startsWith(elem.url)), false,
        );

        return foundUrl ? `menu${i}` : '';
      })
    );
  };

  const changePage = (url, headerTitle, toggleMenu) => {
    if (window.innerWidth <= 425) {
      toggleMenu();
    }

    navigate(url);
    changeTitle(headerTitle);
  };

  useEffect(() => {
    // update selector when the page is refreshed
    if (pathName !== location.pathname) {
      setPathName(location.pathname);

      const pageTitle = getPageTitle();

      changeTitle(pageTitle.headerTitle);
      if (pageTitle.documentTitle) {
        document.title = `Relatórios - ${pageTitle.documentTitle}`;
      } else {
        document.title = 'Relatórios';
      }
    }
  }, [location.pathname]);

  const renderMenuItem = (item) => {
    return (
      <Menu.Item
        key={item.url || item.externalUrl}
        onClick={
          () => {
            if (item.externalUrl) {
              window.open(item.externalUrl, item.externalTarget || '_blank');
            } else {
              changePage(item.url, item.headerTitle, toggleMenu);
            }
          }
        }
      >
        <div className="submenu-container-text">
          <span className="menu-icon">{item.icon}</span>
          <div className={collapsed ? 'ivisible' : ''}>
            {item.name}
          </div>
        </div>
      </Menu.Item>
    );
  };

  const renderSubMenu = (item, index) => {
    const { SubMenu } = Menu;
    const userPermissions = getUserPermissions();

    return (
      <SubMenu
        key={`menu${index}`}
        className="submenu-container"
        title={
          (
            <div className="submenu-container-text">
              <span className="menu-icon">{item.icon}</span>
              <div className={collapsed ? 'ivisible' : ''}>
                {item.name}
              </div>
            </div>
          )
        }
      >
        {item.subMenu.map((subItem) => {
          if (!(subItem.permissions || []).some(
            (element) => (userPermissions || []).includes(element),
          )) {
            return null;
          }

          return (renderMenuItem(subItem));
        })}
      </SubMenu>
    );
  };

  const displayStyle = (window.innerWidth <= 425 && collapsed) ? 'none' : 'block';
  const userPermissions = getUserPermissions();

  return (
    <Sider
      id="SidebarComponent"
      trigger={null}
      style={{ display: displayStyle }}
      collapsible
      collapsed={collapsed}
      width={(window.innerWidth <= 425) ? window.innerWidth * 0.8 : 220}
    >
      <div
        className={`sidebar-logo-section${collapsed ? '-collapsed' : ''}`}
        onClick={() => navigate(routesPaths.root)}
        onKeyDown={(eve) => {
          if (eve.keyCode === 13) {
            navigate(routesPaths.root);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <img
          src={
            collapsed
              ? logoCollapsed
              : logo
          }
          alt=""
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={getOpenKeys()}
        selectedKeys={[pathName]}
      >
        {menuItens.map((item, index) => {
          if (!(item.permissions || [])
            .some((element) => (userPermissions || []).includes(element))) {
            return null;
          }

          return (
            item.standalone
              ? renderMenuItem(item)
              : renderSubMenu(item, index)
          );
        })}
      </Menu>
      <div className="version-container">
        <div className="version">{VERSION}</div>
      </div>
    </Sider>
  );
};

SidebarComponent.propTypes = {};

SidebarComponent.defaultProps = {};

export default SidebarComponent;
