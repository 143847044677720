import { useState } from 'react';

const useAsyncState = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const setter = (val) => new Promise((resolve) => {
    setValue(val);
    resolve(val);
  });

  return [value, setter];
};

export {
  useAsyncState,
};
