const routesPaths = {
  root: '/',
  home: '/home',
  login: '/login',
  forgotPassword: '/forgot-password',
  emailConfirmation: '/email-confirmation',
  resetPassword: '/reset-password',
  changePassword: '/change-password',
  editMyProfile: '/profile/edit-my-profile',
};

export { routesPaths };
