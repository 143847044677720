import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { routesPaths } from '../../router/RoutesPaths';
import { useNavigate } from 'react-router-dom';

const InitialPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/relatorios');
  }, []);

  return (
    <div>InitialPage</div>
  );
};

export default InitialPage;
