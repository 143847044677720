import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Layout as LayoutComponent } from 'antd';
import Header from './headerComponent/HeaderComponent';
import Sidebar from './sidebarComponent/SidebarComponent';
import { toggleMenuCollapsed } from '../redux/actions/app';

const { Content } = LayoutComponent;

const Layout = (props) => {
  const collapsed = useSelector((state) => state.app.collapsed);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(toggleMenuCollapsed());
  };

  useEffect(() => {
    if (window.innerWidth < 664) {
      toggleMenu();
    }
  }, []);

  const { children } = props;

  return (
    <LayoutComponent style={{ minHeight: '100vh' }}>
      <Sidebar />
      <LayoutComponent>
        <Header />
        <Content>
          <div id="main-content">
            {window.innerWidth <= 425 && !collapsed ? null : children}
          </div>
        </Content>
      </LayoutComponent>
    </LayoutComponent>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Layout.defaultProps = {};

export default Layout;
