import React from 'react';

import './Background.scss';
import logo from '../../assets/images/logo-tyra.png';

const Background = () => (
  <div className="background-page">
    <img src={logo} alt="" />
  </div>
);

export default Background;
