import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import './AccessWrapper.scss';
import { checkUserIsAuth } from '../../../utils/users';
import { routesPaths } from '../../../router/RoutesPaths';
import Login from '../login/Login';
import RegisterComponent from '../register/Register';
import BaseFormComponent from '../../../components/base_form_component/BaseFormComponent';

const AccessWrapper = () => {
  const navigate = useNavigate();
  const [enableHeaderClass, setEnableHeaderClass] = useState('');

  useEffect(() => {
    if (checkUserIsAuth()) {
      navigate(routesPaths.root);
    }
  }, []);

  const enableHeader = (enable) => {
    setEnableHeaderClass(enable ? '' : 'remove-header');
  };

  // const { TabPane } = Tabs;

  return (
    <div className="AccessWrapper">
      <BaseFormComponent>
        <Login />
        {/*<Tabs defaultActiveKey="1">*/}
        {/*  <TabPane tab="ENTRAR" key="1">*/}
        {/*    <Login />*/}
        {/*  </TabPane>*/}
        {/*  <TabPane tab="REGISTRAR-SE" key="2">*/}
        {/*    <RegisterComponent />*/}
        {/*  </TabPane>*/}
        {/*</Tabs>*/}
      </BaseFormComponent>
    </div>
  );
};

AccessWrapper.propTypes = {};

AccessWrapper.defaultProps = {};

export default AccessWrapper;
