import request from 'superagent';

import {
  AUTH_URL,
  API_URL,
  INVALID_ACCESS_TOKEN_ERROR_CODE,
  INVALID_REFRESH_TOKEN_ERROR_CODE,
} from '../utils/constants/constants';
import {
  getAccessToken,
  removeUserFromStorage,
} from '../utils/users';
import { HTTPCodes } from '../utils/constants/response-codes';
import { routesPaths } from '../router/RoutesPaths';

const verifyAccessToken = (response) => {
  if (response.statusCode === HTTPCodes.INVALID_ACCESS_TOKEN_ERROR_CODE) {
    removeUserFromStorage();
  }
  if (response.body.error
    && response.body.error.code === HTTPCodes.INVALID_ACCESS_TOKEN_ERROR_CODE) {
  }
};

const sampleGET = () => (
  new Promise((resolve, reject) => (
    request
      .get('https://get.geojs.io/v1/ip/country.json?ip=8.8.8.8')
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })))
);

const fetchCEP = (cep) => (
  new Promise((resolve, reject) => (
    request
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })))
);

const onRefreshToken = (callback, callbackParams) => {
  const payload = {
    command: {
      refreshToken: `aaa`,
    },
  };

  return new Promise((resolve) => (
    request
      .post(API_URL + AUTH_URL)
      .send(payload)
      .set('Content-Type', 'application/json')
      .type('application/json')
      .end(async (error, response) => {
        if (response) {
          if (response.body.error
            && response.body.error.code === INVALID_REFRESH_TOKEN_ERROR_CODE) {
            removeUserFromStorage();
            window.location.href = routesPaths.login;
          } else {

            const newResponse = await callback({ ...callbackParams });

            resolve(newResponse);
          }
        }
      })));
};

const onEndRequest = (
  response,
  error,
  callbackFunction,
  {
    url,
    query,
    params,
    clientOptions,
    id,
  },
) => new Promise(async (resolve, reject) => {
  if (response) {
    verifyAccessToken(response);
    if (response.body.error
      && response.body.error.code === HTTPCodes.INVALID_ACCESS_TOKEN_ERROR_CODE) {
      const refreshResponse = await onRefreshToken(callbackFunction, {
        url, query, params, clientOptions, id,
      });

      resolve(refreshResponse);
    } else if (clientOptions
      && 'returnAll' in clientOptions
      && clientOptions.returnAll === true) {
      resolve(response);
    } else {
      resolve(response.body);
    }
  } else {
    reject(error);
  }
});

const get = ({
  url, query, params, clientOptions,
}) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .query(query || null)
      .send(params || null)
      .end((error, response) => {
        resolve(
          onEndRequest(
            response,
            error,
            get,
            {
              url,
              query,
              params,
              clientOptions,
            },
          ),
        );
      })));
};

const post = ({
  url, query, params, clientOptions,
}) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .post(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(
            response,
            error,
            post,
            {
              url,
              query,
              params,
              clientOptions,
            },
          ),
        );
      })));
};

const put = ({
  url, params, query, clientOptions,
}) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .put(API_URL + url)
      .query(query || null)
      .send(params || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(
            response,
            error,
            put,
            {
              url,
              query,
              params,
              clientOptions,
            },
          ),
        );
      })));
};

const patch = ({
  url, params, id, query, clientOptions,
}) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .patch(API_URL + url + (id || ''))
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(
            response,
            error,
            patch,
            {
              url,
              query,
              params,
              clientOptions,
              id,
            },
          ),
        );
      })));
};

const del = ({
  url, params, query, clientOptions,
}) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .delete(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(
            response,
            error,
            del,
            {
              url,
              query,
              params,
              clientOptions,
            },
          ),
        );
      })));
};

const download = ({ url }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve) => (
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .responseType('blob')
      .end((err, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response);
        } else {
          resolve(err);
        }
      })
  ));
};

const importWhatsappRequest = ({ typeformFile, destinatarios, params }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve, reject) => (
    request
      .post(`${API_URL}/import/whatsapp`)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .attach('typeformFile', typeformFile)
      .attach('destinatariosFile', destinatarios)
      .field('campaign', params.campaign)
      .field('date', params.date)
      .field('hasImage', params.hasImage)
      .field('companyId', params.companyId)
      .field('percentage', params.percentage)
      .field('percentageOpen', params.percentageOpen)
      .field('percentageClicked', params.percentageClicked || 0)
      .field('horaEnvio', params.horaEnvio)
      .field('ignoraRepetido', params.ignoraRepetido)
      // .field('dateAbertura', params.dateAbertura)
      // .field('horaAbertura', params.horaAbertura)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })));
};

const importSMSRequest = ({ comteleFile, params }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve, reject) => (
    request
      .post(`${API_URL}/import/sms`)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .attach('comteleFile', comteleFile)
      .field('campaign', params.campaign)
      .field('base', params.base)
      .field('message', params.message)
      .field('companyId', params.companyId)
      .field('ignoraRepetido', params.ignoraRepetido)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })));
};

const importEmailRequest = ({ typeformFile, sendinblueFile, params }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve, reject) => (
    request
      .post(`${API_URL}/import/email`)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      // .attach('typeformFile', typeformFile)
      .attach('sendinblueFile', sendinblueFile)
      .field('campaign', params.campaign)
      .field('base', params.base)
      .field('data', params.data)
      .field('hora', params.hora)
      .field('mensagem', params.mensagem)
      .field('remetente', params.remetente)
      .field('companyId', params.companyId)
      .field('ignoraRepetido', params.ignoraRepetido)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })));
};

const importSafraRequest = ({ safraFile, params }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve, reject) => (
    request
      .post(`${API_URL}/import/safra`)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .attach('safraFile', safraFile)
      .field('campaign', params.campaign)
      .field('deviceWindows', params.deviceWindows)
      .field('deviceMac', params.deviceMac)
      .field('deviceIphone', params.deviceIphone)
      .field('deviceMobileAndroid', params.deviceMobileAndroid)
      .field('deviceAndroid', params.deviceAndroid)
      .field('deviceIOS', params.deviceIOS)
      .field('date', params.date)
      .field('ignoraRepetido', params.ignoraRepetido)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })));
};

const importWhatsappCsv = ({ whatsappCsv, params }) => {
  const accessToken = getAccessToken();

  return new Promise((resolve, reject) => (
    request
      .put(`${API_URL}/report/updateWhatsAppByCsv`)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .attach('whatsAppCsvFile', whatsappCsv)
      .field('id', params.reportId)
      .end((error, response) => {
        if (response) {
          verifyAccessToken(response);
          resolve(response.body);
        } else {
          reject(error);
        }
      })));
};

export {
  sampleGET,
  fetchCEP,
  get,
  post,
  put,
  patch,
  del,
  download,
  importWhatsappRequest,
  importSMSRequest,
  importEmailRequest,
  importSafraRequest,
  importWhatsappCsv,
};
