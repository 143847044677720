import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';
import './Login.scss';
import { LoginCodes } from '../../../utils/constants/response-codes';
import MessageUtils from '../../../utils/message-utils';
import {
  removeUserFromStorage,
  checkUserIsAuth,
  saveLoginUserData,
  keyVariables
} from '../../../utils/users';
import { userAuthenticate, userAuthenticateWithFacebook, userAuthenticateWithGoogle } from '../../../dataSource/auth_requests';
import { routesPaths } from '../../../router/RoutesPaths';
import { fieldEmailRules, fieldPasswordRules } from '../../../utils/validations';
import HyperlinkButton from '../../../components/hyperlinkButton/HyperlinkButton';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [formLogin] = Form.useForm();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (checkUserIsAuth()) {
      navigate(routesPaths.root);
    }
  }, [navigate]);

  const handleLoginResponse = useCallback(
    async (response) => {
      setLoading(false);

      if (!response.error) {
        console.log('response ', response);
        // Encrypty and save in local storage
        saveLoginUserData(response.token, response.permissions, response.id);
        console.log('1');
        navigate(routesPaths.root);
        console.log('2');
      } else {
        switch (response.code) {
          case LoginCodes.error.INVALID_CREDENTIALS:
            MessageUtils.error({
              message: 'E-mail e/ou senha inválidos',
              description: 'Verifique e tente novamente.',
            });
            break;

          case LoginCodes.error.FACEBOOK_ACCOUNT_NOT_FOUND:
            MessageUtils.error({
              message: 'Este Facebook não pertence a uma conta registrada.',
              description: 'Clique em registrar-se',
            });
            break;

          case LoginCodes.error.FACEBOOK_COMMUNICATION:
            MessageUtils.error({
              message: 'Não foi possível conectar com o Facebook.',
            });
            break;

          case LoginCodes.error.GOOGLE_ACCOUNT_NOT_FOUND:
            MessageUtils.error({
              message: 'Esta conta Google não pertence a uma conta registrada.',
              description: 'Clique em registrar-se',
            });
            break;

          case LoginCodes.error.GOOGLE_COMMUNICATION:
            MessageUtils.error({
              message: 'Não foi possível conectar com o Google.',
            });
            break;

          case LoginCodes.error.USER_NOT_ACTIVATED:
            MessageUtils.error({
              message: 'Usuário inativo',
              description: 'Entre em contato com a administração.',
            });
            break;

          default:
            MessageUtils.error();
            break;
        }

        localStorage.removeItem(keyVariables.userToken);
        localStorage.removeItem(keyVariables.userPermissions);
        localStorage.removeItem(keyVariables.userType);
        localStorage.removeItem(keyVariables.userId);
      }
    },
    [navigate],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const email = formLogin.getFieldValue('email');
    const password = formLogin.getFieldValue('password');

    const response = await userAuthenticate({
      email,
      password,
    });

    handleLoginResponse(response);
  }, [formLogin, handleLoginResponse]);

  // const responseFacebook = useCallback(
  //   async facebookResponse => {
  //     if (facebookResponse.accessToken) {
  //       const response = await userAuthenticateWithFacebook({
  //         accessToken: facebookResponse.accessToken,
  //       });
  //       handleLoginResponse(response);
  //     } else {
  //       MessageUtils.error({
  //         message: 'Não foi possível autenticar com o Facebook.',
  //       });
  //     }
  //   },
  //   [handleLoginResponse],
  // );

  // const responseGoogle = useCallback(
  //   async googleResponse => {
  //     if (googleResponse.accessToken) {
  //       const response = await userAuthenticateWithGoogle({
  //         accessToken: googleResponse.tokenObj.id_token,
  //       });
  //       handleLoginResponse(response);
  //     } else {
  //       MessageUtils.error({
  //         message: 'Não foi possível autenticar com o Google.',
  //       });
  //     }
  //   },
  //   [handleLoginResponse],
  // );

  // const responseGoogleFailed = useCallback(async () => {
  //   MessageUtils.error({
  //     message: 'Não foi possível autenticar com o Google.',
  //   });
  // }, []);

  //
  // async function handleSubmit() {
  //   setLoading(true);
  //
  //   const email = formLogin.getFieldValue('email');
  //   const password = formLogin.getFieldValue('password');
  //
  //   const response = await userAuthenticate({
  //     email,
  //     password,
  //   });
  //
  //   setLoading(false);
  //
  //   if (!response.error) {
  //     // Encrypty and save in local storage
  //     saveLoginUserData(response.token, response.permissions, response.id);
  //
  //     // Change page
  //     navigate(routesPaths.root);
  //   } else {
  //     switch (response.code) {
  //       case LoginCodes.error.INVALID_CREDENTIALS:
  //         MessageUtils.error({
  //           message: 'E-mail e/ou senha inválidos',
  //           description: 'Verifique e tente novamente.',
  //         });
  //         break;
  //
  //       case LoginCodes.error.EMAIL_NOT_CONFIRMED:
  //         MessageUtils.error({
  //           message: 'E-mail ainda não confirmado',
  //           description: 'Confirme seu e-mail para acessar o sistema.',
  //           btn: (
  //             <a
  //               style={{ color: colors.primary.main, textDecoration: 'underline' }}
  //               onClick={() => sendEmailVerification(email)}
  //               tabIndex={0}
  //               onKeyDown={
  //                 (evt) => {
  //                   if (evt.keyCode === 13) {
  //                     sendEmailVerification(email);
  //                   }
  //                 }
  //               }
  //               role="button"
  //             >
  //               Reenviar e-mail
  //             </a>
  //           ),
  //         });
  //         break;
  //
  //       case LoginCodes.error.USER_NOT_ACTIVATED:
  //         MessageUtils.error({
  //           message: 'Usuário inativo',
  //           description: 'Entre em contato com a sua filial.',
  //         });
  //         break;
  //
  //       default:
  //         MessageUtils.error();
  //         break;
  //     }
  //
  //     removeUserFromStorage();
  //   }
  // }
  //
  // function sendEmailVerification(email) {
  //   resendEmailConfirmation({ email }).then((response) => {
  //     if (!response.error) {
  //       MessageUtils.success({
  //         message: 'Sucesso',
  //         description: 'E-mail enviado. Verifique sua caixa de entrada.',
  //       });
  //     } else {
  //       MessageUtils.error();
  //     }
  //   }).catch(() => {
  //     MessageUtils.error();
  //   });
  // }

  return (
    <div className="Login">
      <Form
        form={formLogin}
        className="Login-form"
        onFinish={() => handleSubmit()}
        noValidate
      >
        <Form.Item
          name="email"
          // rules={fieldEmailRules()}
        >
          <Input
            className="InputField"
            type="email"
            placeholder="Login"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={fieldPasswordRules()}
        >
          <Input
            className="InputField"
            type="password"
            placeholder="Senha"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="PrimaryButton"
            loading={isLoading}
          >
            ENTRAR
          </Button>
        </Form.Item>

      </Form>
    </div>
  );

  return <div>LOGIN</div>
};

export default Login;
