export const HTTPCodes = {
  SUCCESS: 200,
  INVALID_ACCESS_TOKEN_ERROR_CODE: 401,
};

export const LoginCodes = {
  error: {
    INVALID_CREDENTIALS: -2000,
    EMAIL_NOT_CONFIRMED: -2001,
    USER_NOT_ACTIVATED: -2002,
    FACEBOOK_AUTHENTICATION: -2010,
    FACEBOOK_COMMUNICATION: -2011,
    GOOGLE_AUTHENTICATION: -2013,
    GOOGLE_COMMUNICATION: -2014,
    FACEBOOK_ACCOUNT_NOT_FOUND: -2016,
    GOOGLE_ACCOUNT_NOT_FOUND: -2017,
  },
};

export const RegisterCodes = {
  success: 2002,

  error: {
    EMAIl_ALREADY_IN_USE: -2003,
  },
};

export const EmailConfirmationCodes = {
  SUCCESS: 2009,

  error: {
    EMAIL_TOKEN_NOT_FOUND: -2007,
    INVALID_EMAIL_TOKEN: -2008,
  },
};

export const ForgotPasswordCodes = {
  SUCCESS: 2003,

  error: {
    USER_NOT_FOUND: -2004,
  },
};

export const ResetPasswordCodes = {
  SUCCESS: 2010,

  error: {
    TOKEN_NOT_FOUND: -2009,
    INVALID_TOKEN: -2008,
  },
};

export const ChangePasswordCodes = {
  SUCCESS: 2004,

  error: {
    INVALID_OLD_PASSWORD: -2005,
  },
};

export const GetMyProfileCodes = {
  SUCCESS: 2006,
};

export const EditMyProfileCodes = {
  success: {
    EMAIL_CHANGED: 2007,
    EMAIL_NOT_CHANGED: 2008,
  },

  error: {
    EMAIL_ALREADY_IN_USE: -2003,
  },
};
