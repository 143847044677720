// React Imports
import React from 'react';
import PropTypes from 'prop-types';

// Ant Design, Icons and Stylesheet
import { Col, Row } from 'antd';
import './BaseFormComponent.scss';

// Assets, Constants and Resources
import logo from '../../assets/images/logo-tyra.png';

// Components
import Background from '../background/Background';

const BaseFormComponent = (props) => {
  const { children } = props;

  return (
    <div className="BaseFormComponent">
      <Row>
        <Col xs={{ span: 0 }} md={{ span: 14 }} className="base-background">
          <Background />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          className="base-form-content"
        >
          <div>
            <img className="logo-image" src={logo} alt="" />
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

BaseFormComponent.propTypes = {
  children: PropTypes.any.isRequired,
};

BaseFormComponent.defaultProps = {};

export default BaseFormComponent;
