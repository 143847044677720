import { get, put, post } from './data_repository';

export const userAuthenticate = ({ email, password }) => {
  const loginEndpoint = '/auth/signin';

  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params: {
        email,
        password,
      },
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};


export const userAuthenticateWithFacebook = ({ accessToken  }) => {
  const loginEndpoint = '/auth/signinWithFacebook';

  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params: {
        accessToken,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userAuthenticateWithGoogle = ({ accessToken  }) => {
  const loginEndpoint = '/auth/signinWithGoogle';

  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params: {
        accessToken,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userLogout = () => {
  const logoutEndpoint = '/auth/logout/';

  return new Promise((resolve, reject) => {
    post({
      url: logoutEndpoint,
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const userSignUp = ({
  email, name, telephone, password,
}) => {
  const signUpEndPoint = '/auth/signup/';

  return new Promise((resolve, reject) => {
    post({
      url: signUpEndPoint,
      params: {
        email,
        name,
        telephone,
        password,
      },
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const resendEmailConfirmation = ({ email }) => {
  const resendEmailEndPoint = `/auth/resendEmailConfirmation/${email}`;

  return new Promise((resolve, reject) => {
    get({
      url: resendEmailEndPoint,
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const emailConfirmation = ({ token }) => {
  const emailConfirmationEndPoint = `/auth/emailConfirmation/${token}`;

  return new Promise((resolve, reject) => {
    put({
      url: emailConfirmationEndPoint,
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const forgotPassword = ({ email }) => {
  const forgotPasswordEndPoint = `/auth/forgotPassword/${email}`;

  return new Promise((resolve, reject) => {
    post({
      url: forgotPasswordEndPoint,
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const resetPassword = ({ token, newPassword }) => {
  const resetPasswordEndPoint = '/auth/passwordReset/';

  return new Promise((resolve, reject) => {
    put({
      url: resetPasswordEndPoint,
      params: {
        token,
        newPassword,
      },
    })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
