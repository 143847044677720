import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import './App.scss';
// import ptBR from 'antd/lib/locale-provider/pt_BR';

// import 'antd/dist/antd.less';
import AppRouter from './router/AppRouter';
import store from './redux/store';

const App = () => (
  <Provider store={store}>
    <ConfigProvider
      theme={{ hashed: false }}
//       locale={ptBR}
    >
      <AppRouter />
    </ConfigProvider>
  </Provider>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
