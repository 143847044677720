import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import './NewPassword.scss';
import { NewPasswordMessages } from '../../assets/strings/strings';
import { fieldPasswordRules } from '../../utils/validations';

const NewPassword = (props) => {
  const {
    form, showLabel, passwordPlaceHolder, confirmPasswordPlaceHolder,
  } = props;

  const compareToFirstPassword = (_, value, __) => {
    return new Promise((resolve, reject) => {
      if (value && value !== form.getFieldValue('password')) {
        reject(NewPasswordMessages.passwordsMustMatch);
      } else {
        resolve();
      }
    });
  };

  return (
    <div className="NewPassword">
      <Form.Item
        className="register-form"
        name="password"
        label={showLabel ? passwordPlaceHolder : null}
        rules={fieldPasswordRules()}
      >
        <Input.Password
          className="InputField"
          placeholder={passwordPlaceHolder}
        />
      </Form.Item>

      <Form.Item
        className="register-form"
        name="confirmPassword"
        label={showLabel ? confirmPasswordPlaceHolder : null}
        dependencies={['password']}
        rules={[
          { required: true, message: NewPasswordMessages.insertYourPassword },
          { validator: compareToFirstPassword },
        ]}
      >
        <Input.Password
          className="InputField"
          placeholder={confirmPasswordPlaceHolder}
        />
      </Form.Item>
    </div>
  );
};

NewPassword.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  showLabel: PropTypes.bool,
  passwordPlaceHolder: PropTypes.string,
  confirmPasswordPlaceHolder: PropTypes.string,
};

NewPassword.defaultProps = {
  showLabel: false,
  passwordPlaceHolder: NewPasswordMessages.passwordPlaceholder,
  confirmPasswordPlaceHolder: NewPasswordMessages.confirmPasswordPlacholder,
};

export default NewPassword;
