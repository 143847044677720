import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined } from '@ant-design/icons';

import './HeaderComponent.scss';
import { toggleMenuCollapsed } from '../../redux/actions/app';
import { userLogout } from '../../dataSource/auth_requests';
import { removeUserFromStorage } from '../../utils/users';
import { routesPaths } from '../../router/RoutesPaths';

import HeaderMenu from './HeaderMenu';

const { Header } = Layout;

const HeaderComponent = () => {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.app.collapsed);
  const headerTitle = useSelector((state) => state.app.headerTitle);
  const dispatch = useDispatch();

  const toggleMenu = () => dispatch(toggleMenuCollapsed());

  const handleLogout = async () => {
    userLogout();
    removeUserFromStorage();
    navigate(routesPaths.login);
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      handleLogout();
    } else if (key === 'changePassword') {
      navigate(routesPaths.changePassword);
    // } else if (key === 'profileEdit') {
    //   navigate(routesPaths.editMyProfile);
    }
  };

  return (
    <Header id="HeaderComponent">
      <div className="header-title-container">
        {
          collapsed
            ? <MenuUnfoldOutlined className="trigger" onClick={toggleMenu} />
            : <MenuFoldOutlined className="trigger" onClick={toggleMenu} />
        }
        <div className="header-title">{headerTitle}</div>
      </div>

      <div className="header-info">
        <div className="custom-header-menu-user-info">
          <Dropdown
            overlay={() => (
              <HeaderMenu onClick={handleMenuClick} />
            )}
            trigger={['click']}
          >
            <div className="dropdown-logout-container">
              <SettingOutlined className="HeaderComponent__settings-icon" />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

HeaderComponent.propTypes = {};

HeaderComponent.defaultProps = {};

export default HeaderComponent;
