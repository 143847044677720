import { routesPaths } from '../router/RoutesPaths';
import { BASIC_PERMISSION } from './constants/permissions-feature';
import { encrypty, decrypty } from './helpers';

// const navigate = useNavigate();

const keyVariables = {
  userToken: 'user_token',
  userPermissions: 'user_permissions',
  userType: 'user_type',
  userId: 'user_id',
};

const removeUserFromStorage = () => {
  localStorage.removeItem(keyVariables.userToken);
  localStorage.removeItem(keyVariables.userPermissions);
  localStorage.removeItem(keyVariables.userType);
  localStorage.removeItem(keyVariables.userId);
  console.log('routesPaths.login ', routesPaths.login);
  window.location.href = routesPaths.login;
};

const setStorageValue = (key) => {
  try {
    return JSON.parse(decrypty(localStorage.getItem(key)));
  } catch (error) {
    console.log(error);
    removeUserFromStorage();

    return false;
  }
};

const getStorageValue = (key) => {
  try {
    return decrypty(localStorage.getItem(key));
  } catch (error) {
    // Logout
    console.log(error);
    removeUserFromStorage();

    return false;
  }
};

const getAccessToken = () => getStorageValue(keyVariables.userToken);

const getUserPermissions = () => JSON.parse(
  getStorageValue(keyVariables.userPermissions),
);

const checkUserIsAuth = () => {
  const token = getAccessToken();

  return !!token;
};

const saveLoginUserData = (token, permissions, id) => {
  const permissionsList = [{
    code: BASIC_PERMISSION,
    name: '*',
  }, ...permissions];

  const finalPermissions = permissionsList.map((value) => value.code);

  // Encrypty and save in local storage
  localStorage.setItem(keyVariables.userToken, encrypty(token));
  localStorage.setItem(keyVariables.userPermissions, encrypty(JSON.stringify(finalPermissions)));
  localStorage.setItem(keyVariables.userId, encrypty(id.toString()));
};

export {
  keyVariables,
  setStorageValue,
  getStorageValue,
  removeUserFromStorage,
  getAccessToken,
  getUserPermissions,
  checkUserIsAuth,
  saveLoginUserData,
};
