import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ProfilePermissions, BASIC_PERMISSION } from '../utils/constants/permissions-feature';

// import AuthRoute from './AuthRoute';
import PrivateRoute from './PrivateRoute';
import { routesPaths } from './RoutesPaths';

import AccessWrapper from '../pages/accessPages/accessWrapper/AccessWrapper';
import ForgotPasswordPage from '../pages/forgotPassword/ForgotPassword';

import EmailConfirmationComponent from '../pages/emailConfirmation/EmailConfirmation';
import ResetPasswordPage from '../pages/resetPassword/ResetPassword';

import InitialPage from '../pages/initial/InitialPage';
import Home from '../pages/home/Home';
import ChangePassword from '../pages/changePassword/ChangePassword';
import EditMyProfile from '../pages/profile/editMyProfile/EditMyProfile';

import NotFound from '../pages/notFound/NotFound';

import ImportFormWhatsapp from '../pages/import/ImportFormWhatsapp';
import ImportFormSMS from '../pages/import/ImportFormSMS';
import ImportFormEmail from '../pages/import/ImportFormEmail';
import ImportFormSafra from '../pages/import/ImportFormSafra';
import ReportList from '../pages/report/ReportList';
import MonthReportList from '../pages/report/monthReportList/MonthReportList';
import MonthTotalList from '../pages/report/monthTotalList/MonthTotalList';
import ReportDetails from '../pages/report/reportDetails/ReportDetails';
// import ReportChartDetails from '../pages/report/reportChartDetails/ReportChartDetails';
import SyncGoogleSheet from '../pages/report/syncGoogleSheet/SyncGoogleSheet';
import ReportGoogleSheet from '../pages/report/reportGoogleSheet/ReportGoogleSheet';
import CompanyList from '../pages/company/CompanyList';
import CompanyRegister from '../pages/company/CompanyRegister';
import CompanyGroupList from '../pages/companyGroup/CompanyGroupList';
import CompanyGroupRegister from '../pages/companyGroup/CompanyGroupRegister';
import UserList from '../pages/user/UserList';
import UserRegister from '../pages/user/UserRegister';

import '../assets/styles/styles.scss';
import EditFormWhatsapp from '../pages/edit/EditFormWhatsapp';

export default function AppRouter() {
  return (
    <Router>
      <Routes>

        <Route exact path='/' element={<PrivateRoute permissions={[BASIC_PERMISSION]} />}>
          <Route path={routesPaths.root}  element={<InitialPage />} />
          <Route exact path={routesPaths.root} element={<InitialPage />} />
        </Route>

        <Route exact path='/' element={<PrivateRoute permissions={['EDIT_SYSTEM_PARAMETER']} />}>
          <Route exact path="/status/sync" element={<SyncGoogleSheet />} />
          <Route exact path="/editar/:id" element={<EditFormWhatsapp />} />

          <Route exact path="/empresas/listar" element={<CompanyList />} />
          <Route exact path="/empresas/cadastrar" element={<CompanyRegister />} />

          <Route exact path="/grupos/listar" element={<CompanyGroupList />} />
          <Route exact path="/grupos/cadastrar" element={<CompanyGroupRegister />} />

          <Route exact path="/usuarios/listar" element={<UserList />} />
          <Route exact path="/usuarios/cadastrar" element={<UserRegister />} />
          <Route exact path="/usuarios/editar/:id" element={<UserRegister />} />

          <Route path="/importar/whatsapp" element={<ImportFormWhatsapp />} />
          <Route path="/importar/sms" element={<ImportFormSMS />} />
          <Route path="/importar/email" element={<ImportFormEmail />} />
          <Route path="/importar/safra" element={<ImportFormSafra />} />

        </Route>

        <Route exact path='/' element={<PrivateRoute permissions={[BASIC_PERMISSION]} />}>
          <Route exact path="/relatorios" element={<ReportList />} />
          <Route exact path="/relatorio-mensal/:type" element={<MonthReportList />} />
          <Route exact path="/relatorio/:id" element={<ReportDetails />} />
          <Route exact path="/relatorio-total" element={<MonthTotalList />} />
          <Route exact path="/status/:type" element={<ReportGoogleSheet />} />
        </Route>

        <Route exact path='/' element={<PrivateRoute permissions={[ProfilePermissions.CHANGE_PASSWORD]} />}>
          <Route path={routesPaths.changePassword} element={ChangePassword} />
        </Route>

        <Route exact path='/' element={<PrivateRoute permissions={[ProfilePermissions.VIEW_MY_PROFILE]} />}>
          <Route path={routesPaths.editMyProfile} element={EditMyProfile} />
        </Route>




        {/*
        <AuthRoute exact path="/graficos/:id" component={ReportChartDetails} permissions={[BASIC_PERMISSION]} />
        */}

        <Route exact path={routesPaths.login} element={<AccessWrapper />} />
        <Route path={routesPaths.forgotPassword} element={<ForgotPasswordPage />} />
        <Route path={`${routesPaths.emailConfirmation}/:token`} element={<EmailConfirmationComponent />} />
        <Route path={`${routesPaths.resetPassword}/:token`} element={<ResetPasswordPage />} />

        <Route component={NotFound} />

      </Routes>
    </Router>
  );
}
