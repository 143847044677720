export const GeneralMessages = {
  genericErrorMessage: 'Ocorreu um erro',
  loading: 'Carregando',
  success: 'Sucesso',
  permissionDenied: 'Permissão negada',
  redirectToHome: 'Você não tem permissão para acessar essa funcionalidade. Você será redirecionado para a página inicial.',
};

export const LoginPageMessages = {

  register: {
    personalDataForm: 'DADOS PESSOAIS',
    passwordForm: 'SENHA',

    insertYourName: 'Por favor, insira seu nome.',
    insertYourTelephone: 'Por favor, insira seu telefone.',

    invalidPhoneNumber: 'Telefone inválido',
    passwordAndConfirmPasswordMustBeEquals: 'Senha e confirmar senha devem ser iguais',

    namePlaceholder: 'Nome',
    confirmPasswordPlacholder: 'Confirmar Senha',
    acceptTermsConditionsAndUse: 'Por favor, aceite os Termos e Condições de uso',

    back: 'VOLTAR',
    continue: 'CONTINUAR',
    finishRegister: 'FINALIZAR CADASTRO',

    iReadAndAceptThe: 'Eu li e aceito os',
    termsAndConditionsOfUse: 'Termos e Condições de uso',
    termsAndConditionsFileName: 'Termos e Condições',
    documentNotFound: 'Documento não encontrado',

    emailSentTo1of2: 'Um e-mail de confirmação foi enviado para',
    emailSentTo2of2: 'Verifique sua caixa de entrada.',

    occurAnErrorWhileSignUp: 'Ocorreu um erro ao realizar o cadastro',
    emailAlreadyInUse: 'Email já foi usado na plataforma. Por favor, altere o e-mail e tente novamente.',
  },
};

export const EmailConfirmationMessages = {
  emailConfirmedSuccessfully: 'E-mail confirmado com sucesso!',
  loginNow: 'Agora você já pode fazer o login no aplicativo.',
  invalidToken: 'Token inválido!',
  doLogin: 'FAZER LOGIN',
};

export const ForgotPasswordMessages = {
  forgotPassword: 'Esqueceu a senha?',
  insertYourEmail: 'Insira seu email cadastrado e te enviaremos as instruções para a recuperação da senha.',
  confirm: 'CONFIRMAR',
  backToLogin: 'voltar para o login',

  verifyYourEmail: 'Verifique seu email.',
  instructionsSent: 'Enviamos instruções ao endereço de email informado.',
  userNotFound: 'Usuario não encontrado.',
  verifyAndTryAgain: 'Verifique e tente novamente.',
};

export const ResetPasswordMessages = {
  confirmButton: 'SALVAR',
  backToLogin: 'voltar para o login',
  passwordResetSuccessfully: 'Senha redefinida com sucesso.',
  youCanLogiNow: 'Agora você pode fazer login no sistema utilizando sua nova senha.',
  notFoundResetPasswordToken: 'Token de redefinição de senha não encontrado.',
  invalidTokenResetPassword: 'Token de redefinição de senha inválido.',
};

export const NewPasswordMessages = {
  passwordsMustMatch: 'Nova senha e confirmar nova senha devem ser iguais',
  invalidPassword: 'As senhas devem ter no mínimo 6 digitos',
  insertYourPassword: 'Por favor, insira sua senha',
  passwordPlaceholder: 'Senha',
  confirmPasswordPlacholder: 'Confirmar senha',
};

export const BarAndHeaderMessages = {
  logout: 'Sair',
  changePassword: 'Alterar Senha',
  myProfile: 'Meu Perfil',
  home: 'Início',
  profile: 'Perfil',
};

export const ChangePasswordMessages = {
  pageTitle: 'Alterar Senha',
  currentPassword: 'Senha atual',
  newPasswordPlaceholder: 'Nova senha',
  confirmNewPasswordPlacholder: 'Confirmar nova senha',
  changePasswordSubmit: 'Alterar Senha',
  cancel: 'Cancelar',
  passwordUpdatedSuccessfully: 'Senha alterada com sucesso!',
  incorrectPassword: 'Senha incorreta',
  incorrectPasswordTryAgain: 'Por favor, digite a senha atual corretamente para poder cadastrar uma nova senha',
  updatedDataWillBeLost: 'Os dados alterados serão perdidos!',
  areYouSureWantCancel: 'Tem certeza que deseja cancelar?',
  yesCancel: 'Sim, cancelar sem salvar',
  no: 'Não',
};

export const EditMyProfileMessages = {
  pageTitle: 'Meu Perfil',
  errorLoadingUserData: 'Ocorreu um erro ao carregar as informações do seu perfil',
  errorLoadingUserDataDetails: 'Não será possível visualizar ou editar as informações do seu perfil. Por favor, tente novamente mais tarde.',
  cancelButton: 'Cancelar',
  backButton: 'Voltar',
  saveButton: 'Salvar',
  fieldName: 'Nome',
  fieldEmail: 'E-mail',
  fieldTelephone: 'Telefone',
  notConfirmedEmail: 'Email não confirmado',
  confirmedEmail: 'Email confirmado',
  loadingData: 'Carregando dados do perfil',
  updatingData: 'Atualizando dados do perfil',
  dataUpdatedSuccessfully: 'Dados atualizados com sucesso!',
  checkEmailAndLoginAgain: 'Agora é necessário confirmar o e-mail alterado e fazer login novamente. Verifique sua caixa de entrada.',
  errorUpdatingData: 'Não foi possível atualizar os dados do seu perfil.',
  emailAlreadyInUse1Of2: 'O e-mail',
  emailAlreadyInUse2Of2: 'já foi utilizado na plataforma. Por favor, altere o e-mail e tente novamente',
  updatedDataWillBeLost: 'Os dados alterados serão perdidos!',
  areYouSureWantCancel: 'Tem certeza que deseja cancelar?',
  yesCancel: 'Sim, cancelar sem salvar',
  no: 'Não',
};

export const FieldsMessages = {
  name: {
    insertYourName: 'Por favor, insira seu nome.',
    minimumCharacters: 'Por favor, insira no mínimo 3 caracteres.',
    maximumCharacters: 'O nome deve conter no máximo 150 caracteres.',
  },

  email: {
    insertYourEmail: 'Por favor, insira seu email.',
    invalidEmailFormat: 'Formato de email inválido.',
    dontUseSpecialCharacters: 'Não utilize caracteres especiais, ou acentuados.',
  },

  telephone: {
    insertYourTelephone: 'Por favor, insira seu telefone.',
    invalidPhoneNumber: 'Telefone inválido',
  },

  password: {
    insertYourPassword: 'Por favor, insira sua senha',
    invalidPassword: 'A senha deve ter no mínimo 6 digitos',
  },
};

export const ExampleForm1Messages = {
  messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
  messageErrorSendingData: 'Erro ao enviar os dados',
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
  validationPleaseFillInTheField: 'Por favor, preencha o campo',
  validationCharactersMaximum200: 'Máximo de 200 caracteres',
  buttomSave: 'Salvar',
  buttomCancel: 'Cancelar',
  spinLoading: 'Carregando...',

};

export const ExampleForm2Messages = {
  nameField: 'Nome',
  cpfField: 'CPF',
  emailField: 'E-mail',
  passwordField: 'Senha',
  confirmPasswordField: 'Confirmar senha',
  telephoneField: 'Telefone',
  cnpjField: 'CNPJ',

  messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
  messageErrorSendingData: 'Erro ao enviar os dados',
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
  validationCharactersMinimum200: 'Mínimo de 5 caracteres',
  validationCharactersMaximum200: 'Máximo de 200 caracteres',
  validationCPF: 'Insira um CPF válido',
  validationCNPJ: 'Insira um CNPJ válido',
  validationConfirmPassword: 'As senhas não conferem',

  validationEmail: 'Por favor, insira um e-mail válido',
  validationRequired: 'O campo é obrigatório',

  buttomSave: 'Salvar',
  buttomCancel: 'Cancelar',
  spinLoading: 'Carregando...',

};

export const ExampleForm3Messages = {
  messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
  messageErrorSendingData: 'Erro ao enviar os dados',
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  validationPleaseSelectADate: 'Por favor, selecione uma data',
  validationPleaseSelectARange: 'Por favor, selecione um período',
  buttomSave: 'Salvar',
  buttomCancel: 'Cancelar',
  spinLoading: 'Carregando...',

};

export const ExampleForm4Messages = {
  cepField: 'CEP',
  stateField: 'Estado',
  cityField: 'Cidade',
  addressField: 'Endereço',
  numberField: 'Número',
  neighborhoodField: 'Bairro',
  complementField: 'Complemento',

  messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
  messageErrorSendingData: 'Erro ao enviar os dados',
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
  validationPleaseFillInTheField: 'Por favor, preencha o campo',
  validationCharactersMaximum200: 'Máximo de 200 caracteres',
  buttomSave: 'Salvar',
  buttomCancel: 'Cancelar',
  spinLoading: 'Carregando...',

};

export const ExampleListMessages = {
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  spinLoading: 'Carregando...',

};

export const ExamplePaginationListMessages = {
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  spinLoading: 'Carregando...',

};

export const ExampleFilterListMessages = {
  messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
  messageErrorLoadingData: 'Erro ao carregar os dados',
  spinLoading: 'Carregando...',
  buttomFilter: 'Filtrar',
  buttomClean: 'Limpar',
  validationCharactersMaximum200: 'Máximo de 200 caracteres',
  validationPleaseSelectAnOption: 'Por favor, selecione uma opção',

};
