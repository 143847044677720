import React from 'react';
import { Menu } from 'antd';
import LockResetIcon from 'mdi-react/LockResetIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import PropTypes from 'prop-types';

import { getUserPermissions } from '../../utils/users';
import { BarAndHeaderMessages } from '../../assets/strings/strings';
import { ProfilePermissions } from '../../utils/constants/permissions-feature';

const HeaderMenu = (props) => {
  const { onClick } = props;

  return (
    <Menu
      className="custom-header-menu"
      onClick={onClick}
      mode="inline"
    >
      {/*{*/}
      {/*  ((getUserPermissions() || []).indexOf(ProfilePermissions.VIEW_MY_PROFILE) >= 0)*/}
      {/*    ? (*/}
      {/*      <Menu.Item*/}
      {/*        className="profile-edit"*/}
      {/*        key="profileEdit"*/}
      {/*      >*/}
      {/*        <span>{BarAndHeaderMessages.myProfile}</span>*/}
      {/*        <AccountIcon />*/}
      {/*      </Menu.Item>*/}
      {/*    ) : null*/}
      {/*}*/}

      {
        ((getUserPermissions() || []).indexOf(ProfilePermissions.CHANGE_PASSWORD) >= 0) ? (
          <Menu.Item
            className="change-password"
            key="changePassword"
          >
            <span>{BarAndHeaderMessages.changePassword}</span>
            <LockResetIcon />
          </Menu.Item>
        ) : null
      }

      <Menu.Item
        className="logout"
        key="logout"
      >
        {BarAndHeaderMessages.logout}
      </Menu.Item>
    </Menu>
  );
};

HeaderMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
};

HeaderMenu.defaultProps = {};

export default HeaderMenu;
