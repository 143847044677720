import React from 'react';

import { notification } from 'antd';
import {
  InfoCircleTwoTone, CheckCircleTwoTone, CloseCircleTwoTone, WarningTwoTone,
} from '@ant-design/icons';

import colors from '../assets/styles/colors';
import { GeneralMessages } from '../assets/strings/strings';

class MessageUtils {
  static showMessage(params) {
    notification.destroy();

    notification.open({ ...params });
  }

  static info(params) {
    const notificationParams = { ...params };

    notificationParams.icon = notificationParams.icon
      ? notificationParams.icon
      : <InfoCircleTwoTone twoToneColor={colors.information} />;

    this.showMessage(notificationParams);
  }

  static success(params) {
    const notificationParams = { ...params };

    notificationParams.icon = notificationParams.icon
      ? notificationParams.icon
      : <CheckCircleTwoTone twoToneColor={colors.success} />;

    this.showMessage(notificationParams);
  }

  static error(params) {
    const notificationParams = { ...params };

    notificationParams.message = notificationParams.message
      ? notificationParams.message
      : GeneralMessages.genericErrorMessage;

    notificationParams.icon = notificationParams.icon
      ? notificationParams.icon
      : <CloseCircleTwoTone twoToneColor={colors.error} />;

    this.showMessage(notificationParams);
  }

  static warning(params) {
    const notificationParams = { ...params };

    notificationParams.icon = notificationParams.icon
      ? notificationParams.icon
      : <WarningTwoTone twoToneColor={colors.warning} />;

    this.showMessage(notificationParams);
  }
}

export default MessageUtils;
