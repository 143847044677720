// React Imports
import React from 'react';
import PropTypes from 'prop-types';

// Ant Design, Icons and Stylesheet
import './HyperlinkButton.scss';

const HyperlinkButton = (props) => {
  const { children, onClick, className } = props;

  return (
    <div className={`HyperlinkButton ${className}`}>
      <a
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <span>{children}</span>
      </a>
    </div>
  );
};

HyperlinkButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.any,
  children: PropTypes.any,
};

HyperlinkButton.defaultProps = {};

export default HyperlinkButton;
