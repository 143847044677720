import React, { useEffect, useState } from 'react';
import { Col, message, Progress, Row, Spin, Table } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './ReportGoogleSheet.scss';
import { get } from '../../../dataSource/data_repository';

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (v) => {
      if(!isNaN(v)) {
        return <div className="status-container"><Progress size="small" percent={v} /></div>;
      }
      if (!v) {
        return '';
      }
      if (v.toUpperCase() === 'CANCELADO') {
        return <div className="status-container"><Progress size="small" percent={100} status="exception" /></div>;
      }
      if (v.toUpperCase() === 'COMPLETO') {
        return <div className="status-container"><Progress size="small" percent={100} /></div>;
      }
      return '';
    }
  },
  { // A
    title: 'Nome do cliente',
    dataIndex: 'a',
    key: 'a',
  },
  { // D
    title: 'O disparo é um teste?',
    dataIndex: 'd',
    key: 'd',
    render: (v) => (v ? 'Sim' : 'Não'),
  },
  { // E
    title: 'Campanha',
    dataIndex: 'e',
    key: 'e',
  },
  { // L
    title: 'Lista',
    dataIndex: 'l',
    key: 'l',
    render: (v) => (<a href={v}>Lista</a>)
  },
  { // M
    title: 'Contato Mark solicitante',
    dataIndex: 'm',
    key: 'm',
  },
  { // N
    title: 'Data do disparo',
    dataIndex: 'n',
    key: 'n',
    render: (v) => {
      const tempo = moment(v, "YYYY/MM/DDTHH:mm:ss");
      return tempo.format("DD/MM/YYYY");
    }
  },
  { // S
    title: 'Horário do disparo',
    dataIndex: 's',
    key: 's',
    render: (v) => {
      const tempo = moment(v, "YYYY/MM/DDTHH:mm:ss");
      return tempo.format("HH:mm");
    }
  },
  { // U
    title: 'Enviado em',
    dataIndex: 'u',
    key: 'u',
    render: (v) => {
      const tempo = moment(v, "YYYY/MM/DDTHH:mm:ss");
      return tempo.format("DD/MM/YYYY HH:mm:ss");
    }
  },
];

export default function ReportGoogleSheet() {
  const { type } = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [listData, setListData] = useState([]);
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');

  async function fetchData() {
    get({ url: `/solicitation/list/${type}?size=${1000}&page=${0}&sort=submittedAt,desc` }).then((response) => {
      setLoadingData(false);
      console.log('response ', response);

      if (!response.error) {
        let content = null;

        switch (type) {
          case 'whatsapp':
            content = response?.solicitationWhatsappPage?.content;
            break;
          case 'sms':
            content = response?.solicitationSMSPage?.content;
            break;
          case 'email':
            content = response?.solicitationEmailPage?.content;
            break;
          default:
            console.log('Type error ', type);
        }

        setListData(content?.map((v, i) => ({
          status: v.status,
          a: v.clientName,
          d: v.test,
          e: v.campaign,
          l: v.inputListUrl,
          m: v.contact,
          n: v.triggerDate,
          s: v.triggerDate,
          u: v.submittedAt,
          key: v.submittedAt.replace(/\s/g, ''),
        })));
      } else {
        message.error('Ocorreu um erro ao buscar os dados');
      }
      setLoadingData(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro ao buscar os dados');
      setLoadingData(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, [type]);

  return (
    <div className="ReportGoogleSheet">
      <Spin
        tip="Carregando"
        spinning={loadingData}
      >
        <div className="report-table-container">
          <Table
            className={'report-table'}
            dataSource={listData}
            columns={columns}
            pagination={false}
          />
        </div>
      </Spin>
    </div>
  );
}
