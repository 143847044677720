import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Input, Row, Col, message } from 'antd';
import './CompanyGroupRegister.scss';
import { post } from '../../dataSource/data_repository';

export default function CompanyGroupRegister() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    post({
      url: '/companyGroup',
      params: { name: form.getFieldValue('name') },
    }).then((response) => {
      setLoading(false);
      if (!response.error) {
        message.success('Grupo de empresas criado com sucesso');
        navigate('/grupos/listar');
      } else {
        message.error('Ocorreu um erro ao cadastrar a empresa');
      }
    }).catch((err) => {
      message.error('Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    });

  };

  return (
    <div className="CompanyGroupRegister">
      <Form
        form={form}
        className="CompanyGroupRegisterForm"
        onFinish={() => handleSubmit()}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'O nome é obrigatório' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className="actions-container">
          <Button
            className="btn-secondary btn-action"
            // onClick={() => handleBack()}
            onClick={() => navigate('/grupos/listar')}
          >
            Cancelar
          </Button>

          <Button
            className="btn-primary btn-action"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
}
