const NotificationsPermissions = {
  VIEW_NOTIFICATIONS: 'VIEW_NOTIFICATIONS',
  SEND_NOTIFICATIONS: 'SEND_NOTIFICATIONS',
};

const ProfilePermissions = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  VIEW_MY_PROFILE: 'VIEW_MY_PROFILE',
  EDIT_MY_PROFILE: 'EDIT_MY_PROFILE',
};

const RolePermissions = {
  EDIT_ROLES: 'EDIT_ROLES',
};

const SystemParametersPermissions = {
  VIEW_SYSTEM_PARAMETER: 'VIEW_SYSTEM_PARAMETER',
  EDIT_SYSTEM_PARAMETER: 'EDIT_SYSTEM_PARAMETER',
};

const UserPermissions = {
  USER_REGISTRATION: 'USER_REGISTRATION',
};

const BASIC_PERMISSION = '';

export {
  NotificationsPermissions,
  ProfilePermissions,
  RolePermissions,
  SystemParametersPermissions,
  UserPermissions,
  BASIC_PERMISSION,
};
