import React, { useState } from 'react';
import { Button, message } from 'antd';
import './SyncGoogleSheet.scss';
import { post } from '../../../dataSource/data_repository';

export default function SyncGoogleSheet() {
  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
  const [loadingSMS, setLoadingSMS] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);

  async function startSyncing(type) {
    switch (type) {
      case 'whatsapp':
        if (loadingWhatsapp) return;
        break;
      case 'sms':
        if (loadingSMS) return;
        break;
      case 'email':
        if (loadingEmail) return;
        break;
      default:
        return;
    }

    if (type == 'whatsapp') setLoadingWhatsapp(true);
    if (type == 'sms') setLoadingSMS(true);
    if (type == 'email') setLoadingEmail(true);
    post({ url: `/solicitation/sync/${type}` }).then((response) => {
      console.log('response ', response);

      if (!response.error) {
        let content = null;
        message.success('Srincronização realizada com sucesso');
      } else {
        message.error('Ocorreu um erro ao buscar os dados');
      }
      if (type == 'whatsapp') setLoadingWhatsapp(false);
      if (type == 'sms') setLoadingSMS(false);
      if (type == 'email') setLoadingEmail(false);
    }).catch((err) => {
      console.log('Erro ', err);
      message.error('Ocorreu um erro ao buscar os dados');
      if (type == 'whatsapp') setLoadingWhatsapp(false);
      if (type == 'sms') setLoadingSMS(false);
      if (type == 'email') setLoadingEmail(false);
    });
  }

  return (
    <div className="SyncGoogleSheet">
      <div className="text-header">
        A sincronização ocorre a cada 15 minutos, exatamente nos minutos 00, 15, 30 e 45 de cada hora.
        <br />
        Mas também pode ser executada de forma manual caso necessário.
      </div>
      <div className="actions">
        <Button
          type="primary"
          loading={loadingWhatsapp}
          onClick={() => startSyncing('whatsapp')}
        >
          Iniciar sincronização WhatsApp
        </Button>
        <Button
          type="primary"
          loading={loadingSMS}
          onClick={() => startSyncing('sms')}
        >
          Iniciar sincronização SMS
        </Button>
        <Button
          type="primary"
          loading={loadingEmail}
          onClick={() => startSyncing('email')}
        >
          Iniciar sincronização E-mail
        </Button>
      </div>
    </div>
  );
}
